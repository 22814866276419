import React, { useState, useEffect } from 'react';
import { fetchTemplates} from '../../../API';
import { postWhatsappDashboardata } from '../../../API/panelApis';

// Mock function for sending messages (replace with actual API call)
const sendMessage = async (phoneNumber, message) => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return Math.random() > 0.2; // 80% success rate for demonstration
};

const WhatsappDashboard = ({ user }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [status, setStatus] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  // Fetch templates from API
  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const res = await fetchTemplates();
        setTemplates(res.templates || []); // Use fetched templates
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    loadTemplates();
  }, []);

  // Update message when a template is selected
  useEffect(() => {
    if (selectedTemplate) {
      const template = templates.find((t) => t.id === parseInt(selectedTemplate));
      if (template) {
        setMessage(template.content);
      }
    }
  }, [selectedTemplate]);

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phoneNumber || !message) {
      setStatus({ type: 'danger', message: 'Please fill in all fields.' });
      return;
    }

      setIsLoading(true);
      const requestBody = {
        numbers: phoneNumber,
        template: selectedTemplate, // Pass the selected template ID or name
        body: message, // The message content
      };
    
    try {
        const success = await postWhatsappDashboardata(requestBody);
        if (success) {
        setStatus({ type: 'success', message: 'Message sent successfully!' });
        setMessageHistory((prev) => [
          { id: Date.now(), phoneNumber, message, status: 'sent', timestamp: new Date() },
          ...prev,
        ]);
        resetForm();
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      setStatus({ type: 'danger', message: 'Failed to send message. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setPhoneNumber('');
    setMessage('');
    setSelectedTemplate('');
  };

//   // Return early if the user is not authenticated
//   if (!user?.isAuthenticated) {
//     return <div className="text-center p-4">Please log in to access this feature.</div>;
//   }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">WhatsApp Messaging</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
          <input
            id="phoneNumber"
            type="tel"
            className="form-control"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+1234567890"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="template" className="form-label">Message Template</label>
          <select
            id="template"a
            className="form-select"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id} className='text-dark'>
                {template.template_name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            id="message"
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
            rows={4}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
          {isLoading ? 'Sending...' : 'Send Message'}
        </button>
      </form>

      {status && (
        <div className={`alert alert-${status.type} mt-3`} role="alert">
          {status.message}
        </div>
      )}
    </div>
  );
};

export default WhatsappDashboard;
