import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import useBorrowerInfo from "../store";

export default function Addresses() {
    const [isOpen, setIsOpen] = useState(false);

    const data = useBorrowerInfo((state) => state.data);

    const toggle = () => setIsOpen((prev) => !prev);

    return (
        <>
            <a onClick={toggle} style={{ color: "#118CEC" }} className="fw-medium py-2">
                Addresses
            </a>

            <Offcanvas show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Addresses</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-3 pl-5">
                    <div style={{ borderBottom: "1px dashed #ddd" }} className="pb-3">
                        <p className="text-black fw-bold mb-2 tx-18">Current Address</p>
                        <p className="mb-0 text-black tx-16">{data?.all_addresses?.current_address ?? ""}</p>
                    </div>
                    <div style={{ borderBottom: "1px dashed #ddd" }} className="mt-3 pb-3">
                        <p className="text-black fw-bold mb-2 tx-18">Employer Address</p>
                        <p className="mb-0 text-black tx-16">{data?.data?.office_address1 ?? ""}</p>
                    </div>
                    <div className="mt-3 pb-3">
                        <p className="text-black fw-bold mb-2 tx-18">Permanent Address</p>
                        <p className="mb-0 text-black tx-16">{data?.all_addresses?.permanent_address ?? ""}</p>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
