import { SlLayers } from "react-icons/sl";
import { LiaUserAltSlashSolid, LiaPhoneVolumeSolid } from "react-icons/lia";
import { AiOutlineApi, AiOutlineFileSearch } from "react-icons/ai";
import { MdOutlineSecurity, MdOutlineSupportAgent } from "react-icons/md";
import {
  PiStudentLight,
  PiStudentFill,
  PiSuitcaseSimpleLight,
  PiPhonePlusLight,
  PiMicrophone,
  PiEnvelopeSimpleLight,
  PiWhatsappLogoLight,
  PiTreeStructureLight,
  PiGear,
  PiClipboardText,
  PiChartBarHorizontalLight,
} from "react-icons/pi";
import { BsCheckCircle, BsCollection, BsEnvelopeAt, BsFileEarmarkBarGraph, BsFillCollectionFill } from "react-icons/bs";
import { IoChatbox, IoDocumentLockOutline, IoDocumentText, IoLogoWhatsapp, IoPerson, IoTimer } from "react-icons/io5";
import { LuConstruction } from "react-icons/lu";
import {
  HiOutlineDocumentText,
  HiMiniSignal,
  HiOutlineNewspaper,
  HiOutlineUserGroup,
  HiOutlinePhone,
  HiOutlineDocumentArrowUp,
  HiOutlineChatBubbleLeftRight,
  HiOutlineChatBubbleOvalLeftEllipsis,
  HiOutlineVariable,
  HiOutlineBuildingOffice2,
  HiOutlineCpuChip,
  HiOutlineClipboardDocumentList,
  HiOutlineInboxArrowDown,
  HiOutlineDocument,
} from "react-icons/hi2";
import { BiCategory } from "react-icons/bi";
import { TfiAnnouncement } from "react-icons/tfi";
import { RiOpenaiFill } from "react-icons/ri";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { VscGitPullRequestCreate, VscLayout } from "react-icons/vsc";

export const sidebarData = [
  {
    label: "Campaign",
    items: [
      {
        label: "Manage",
        title: "Manage Campaign",
        icon: TfiAnnouncement,
        href: "/admin/campaigns/manage",
      },
      {
        label: "Summary",
        title: "Notice Campaign Summary",
        icon: HiOutlineDocumentText,
        href: "/admin/campaigns/summary",
      },
      {
        label: "Campaign Status",
        title: "Campaign Status",
        icon: HiMiniSignal,
        href: "/admin/campaigns/campaign-status",
        tags: ["Pending IVR Campaign", "IVR Campaign", "PTP IVR Campaign", "Notice Campaign", "Alternate No.s Campaign"],
      },

      {
        label: "Notice Campaign Report",
        title: "Notice Campaign Report",
        icon: HiOutlineDocument,
        href: "/admin/campaigns/notice-campaign-report",
      },
    ],
  },
  {
    label: "Settlements",
    items: [
      {
        label: "Manage Settlements",
        title: "Manage Settlements",
        icon: HiOutlineUserGroup,
        href: "/admin/settlements/manage-settlements",
      },

      {
        label: "Report",
        title: "Settlement Report",
        icon: HiOutlineClipboardDocumentList,
        href: "/admin/settlements/report",
        tags: ["Settlment Report", "Pre-approved Settlement Amount Update", "Agent Wise Settlement Report", "List of Settlements"],
      },
      {
        label: "Settlement Analysis",
        title: "Settlement Analysis",
        icon: HiOutlineDocument,
        href: "/admin/settlements/settlement-analysis",
      },
      {
        label: "Setl. Stage Analysis",
        title: "Setl. Stage Analysis",
        icon: HiOutlineDocument,
        href: "/admin/settlements/setl-stage-analysis",
      },
    ],
  },
  {
    label: "Daily Ops",
    items: [
      {
        label: "Call Quality",
        title: "Call Quality",
        icon: HiOutlinePhone,
        href: "/admin/daily-ops/call-quality",
      },
      {
        label: "DND List",
        title: "Excluded Customers",
        icon: LiaUserAltSlashSolid,
        href: "/admin/daily-ops/dnd-list",
      },
      {
        label: "Admin APIs",
        title: "Admin APIs",
        icon: AiOutlineApi,
        href: "/admin/daily-ops/admin-apis",
      },
      {
        label: "Exophone Management",
        title: "Exophone Management",
        icon: LiaPhoneVolumeSolid,
        href: "/admin/daily-ops/exophone-management",
      },
      {
        label: "Exophone Change Report",
        title: "Exophone Change Report",
        icon: LiaPhoneVolumeSolid,
        href: "/admin/daily-ops/exophone-change-report",
      },
      {
        label: "Files Upload Report",
        title: "Files Upload Report",
        icon: HiOutlineDocumentArrowUp,
        href: "/admin/daily-ops/file-upload-report",
        tags: ["Payment File Upload", "Batch Update", "Vendor File Upload"],
      },
      {
        label: "Gupshup Mappings",
        title: "Gupshup Mappings",
        icon: HiOutlineChatBubbleLeftRight,
        href: "/admin/daily-ops/gupshup-mappings",
      },
      {
        label: "Gupshup Mapping Summary",
        title: "Gupshup Mapping Summary",
        icon: HiOutlineChatBubbleLeftRight,
        href: "/admin/daily-ops/gupshup-mapping-summary",
      },
      {
        label: "Search FIR",
        title: "Search FIR",
        icon: MdOutlineSecurity,
        href: "/admin/daily-ops/search-fir",
      },
      {
        label: "Payment Receipts",
        title: "Payment Receipts",
        icon: FaIndianRupeeSign,
        href: "/admin/daily-ops/payment-receipts",
      },
      {
        label: "Settlement Report",
        title: "Settlement Report",
        icon: IoDocumentText,
        href: "/admin/daily-ops/settlement-report",
      },
      {
        label: "Chat Panel Report",
        title: "Chat Panel Report",
        icon: IoChatbox,
        href: "/admin/daily-ops/chat-panel-report",
      },
    ],
  },
  {
    label: "Agents",
    items: [
      {
        label: "Manage Agents",
        title: "Manage Agents",
        icon: MdOutlineSupportAgent,
        href: "/admin/agents/manage-agents",
      },
      {
        label: "Agent Report",
        title: "Agent Report",
        icon: HiOutlineDocumentText,
        href: "/admin/agents/agent-report",
        tags: [
          "Full Time Agent Report",
          "Part Time Agent Report",
          "Chat & Email Agent Report",
          "Yesterday's Final Report",
          "Absent Report",
          "Agent Actual Collection Report",
          "Calls Target Avg",
          "Collection Target",
          "Calls Target",
          "Call Time Per Day",
        ],
      },
      {
        label: "Call Disposition Analysis",
        title: "Call Disposition Analysis",
        icon: HiOutlineDocument,
        href: "/admin/agents/call-disposition-analysis",
      },
    ],
  },
  {
    label: "Chat Panel",
    items: [
      {
        label: "Chat Panel",
        title: "Chat Panel",
        icon: MdOutlineSupportAgent,
        href: "/admin/chat-panel/chat",
      },
      {
        label: "Alt Chat Panel",
        title: "Alt Chat Panel",
        icon: MdOutlineSupportAgent,
        href: "/admin/chat-panel/alt-chat",
      },
    ],
  },
  {
    label: "HR",
    items: [
      {
        label: "Candidates",
        title: "Candidates",
        icon: PiStudentFill,
        href: "/admin/hr/candidates",
      },
      {
        label: "Applicants",
        title: "Applicants",
        icon: PiStudentLight,
        href: "/admin/hr/applicants",
      },
      {
        label: "Manage Jobs",
        title: "Manage Jobs",
        icon: PiSuitcaseSimpleLight,
        href: "/admin/hr/manage-jobs",
      },
    ],
  },
  {
    label: "Analytics",
    items: [
      {
        label: "Perfomance Summary",
        title: "Perfomance Summary",
        icon: BsFileEarmarkBarGraph,
        href: "/admin/analytics/perfomance-summary",
        tags: ["Batch Wise", "DPD Wise"],
      },
      {
        label: "Alt. Number Report",
        title: "Alt. Number Report",
        icon: PiPhonePlusLight,
        href: "/admin/analytics/alt-number-report",
      },
    ],
  },
  {
    label: "Templates",
    items: [
      {
        label: "Legal Notices",
        title: "Legal Notices",
        icon: IoDocumentLockOutline,
        href: "/admin/templates/legal-notices",
      },
      {
        label: "SMS",
        title: "SMS",
        icon: HiOutlineChatBubbleOvalLeftEllipsis,
        href: "/admin/templates/sms",
      },
      {
        label: "Whatsapp",
        title: "Whatsapp",
        icon: IoLogoWhatsapp,
        href: "/admin/templates/whatsapp",
      },
      {
        label: "Email",
        title: "Email",
        icon: PiEnvelopeSimpleLight,
        href: "/admin/templates/email",
      },
      {
        label: "IVR",
        title: "IVR",
        icon: PiMicrophone,
        href: "/admin/templates/ivr",
      },
      {
        label: "Manage Category",
        title: "Manage Category",
        icon: BiCategory,
        href: "/admin/templates/manage-category",
      },
      {
        label: "Add Variable",
        title: "Add Variable",
        icon: HiOutlineVariable,
        href: "/admin/templates/add-variable",
      },
      {
        label: "Wati Accounts Management",
        title: "Wati Accounts Management",
        icon: PiWhatsappLogoLight,
        href: "/admin/templates/wati-accounts-management",
      },
      {
        label: "Gupshup Templates",
        title: "Gupshup Templates",
        icon: HiOutlineChatBubbleLeftRight,
        href: "/admin/templates/gupshup-templates",
      },
    ],
  },
  {
    label: "Clients",
    items: [
      {
        label: "Manage Clients",
        title: "Manage Clients",
        icon: HiOutlineBuildingOffice2,
        href: "/admin/clients/manage-clients",
      },
      {
        label: "Process",
        title: "Process",
        icon: HiOutlineCpuChip,
        href: "/admin/clients/process",
        tags: ["Manage Subprocess", "Manage Categories"],
      },
      {
        label: "Features & Settings",
        title: "Features & Settings",
        icon: PiGear,
        href: "/admin/clients/features-settings",
        tags: ["Manage Features", "Client Settings"],
      },
      {
        label: "Borrower Info Mapping",
        title: "Borrower Info Mapping",
        icon: PiTreeStructureLight,
        href: "/admin/clients/borrower-info-mapping",
      },
      {
        label: "Client POC",
        title: "Client POC",
        icon: BsEnvelopeAt,
        href: "/admin/clients/client-poc",
      },
    ],
  },
  {
    label: "Collection Perfomance",
    items: [
      {
        label: "Collection Report",
        title: "Collection Report",
        icon: BsCollection,
        href: "/admin/collection-perfomance/collection-report",
      },
      {
        label: "Recovery Rate (DPD)",
        title: "Recovery Rate (DPD)",
        icon: AiOutlineFileSearch,
        href: "/admin/collection-perfomance/recovery-rate",
      },
      {
        label: "Batch Report",
        title: "Batch Report",
        icon: SlLayers,
        href: "/admin/collection-perfomance/batch-report",
      },
      {
        label: "Legal Calling MIS",
        title: "Legal Calling MIS",
        icon: HiOutlineInboxArrowDown,
        href: "/admin/collection-perfomance/legal-calling-mis",
      },
    ],
  },
  {
    label: "Others",
    items: [
      {
        label: "OpenAI Prompts",
        title: "OpenAI Prompts",
        icon: RiOpenaiFill,
        href: "/admin/others/openai-prompts",
      },
      {
        label: "OpenAI Analysis",
        title: "OpenAI Analysis",
        icon: RiOpenaiFill,
        href: "/admin/others/openai-analysis",
      },
      {
        label: "Generate Retention Data",
        title: "Generate Retention Data",
        icon: VscGitPullRequestCreate,
        href: "/admin/others/generate-retention-data",
      },
      {
        label: "Auto Campaign mapping",
        title: "Auto Campaign mapping",
        icon: PiTreeStructureLight,
        href: "/admin/others/auto-campaign-mapping",
      },
      {
        label: "Dashboard",
        title: "Dashboard",
        icon: VscLayout,
        href: "/admin/others/dashboard",
      },
      {
        label: "User Module",
        title: "User Module",
        icon: IoPerson,
        href: "/admin/others/user-module",
      },
      {
        label: "CRON Management",
        title: "CRON Management",
        icon: IoTimer,
        href: "/admin/others/cron-management",
      },
    ],
  },

  {
    label: "Work In Progress",
    items: [
      // BsCheckCircle,LuConstruction
      {
        label: "Borrower Report",
        title: "Borrower Report",
        icon: BsCheckCircle,
        href: "/admin/wip/borrower-report",
      },
      {
        label: "Conciliation Joining",
        title: "Conciliation Joining",
        icon: BsCheckCircle,
        href: "/admin/wip/conciliation-joining",
      },
      {
        label: "Payment Review",
        title: "Payment Review",
        icon: BsCheckCircle,
        href: "/admin/wip/payment-review",
      },
      {
        label: "Letter Aging Summary",
        title: "Letter Aging Summary",
        icon: BsCheckCircle,
        href: "/admin/wip/letter-aging-summary",
      },
      {
        label: "Allocation Analysis",
        title: "Allocation Analysis",
        icon: BsCheckCircle,
        href: "/admin/wip/allocation-analysis",
      },
      {
        label: "Response Analysis",
        title: "Response Analysis",
        icon: BsCheckCircle,
        href: "/admin/wip/response-analysis",
      },
      {
        label: "Manage Min. Due",
        title: "Manage Min. Due",
        icon: BsCheckCircle,
        href: "/admin/wip/manage-mindue",
      },
      {
        label: "Excluded List",
        title: "Excluded List",
        icon: BsCheckCircle,
        href: "/admin/wip/excluded-list",
      },
      {
        label: "Exotel Campaign Report",
        title: "Exotel Campaign Report",
        icon: BsCheckCircle,
        href: "/admin/wip/exotel-campaign-report",
      },
      {
        label: "Monthly Collector Report",
        title: "Monthly Collector Report",
        icon: BsCheckCircle,
        href: "/admin/wip/monthly-collector-report",
      },
      {
        label: "Helpline Number Report",
        title: "Helpline Number Report",
        icon: LuConstruction,
        href: "/admin/wip/helpline-number-report",
      },
      {
        label: "Fir Working States",
        title: "Fir Working States",
        icon: LuConstruction,
        href: "/admin/wip/fir-working-state",
      },
      {
        label: "Monthly Allocation Analysis",
        title: "Monthly Allocation Analysis",
        icon: LuConstruction,
        href: "/admin/wip/monthly-allocation-analysis",
      },

      {
        label: "Todays Activity",
        title: "Todays Activity",
        icon: LuConstruction,
        href: "/admin/wip/todays-activity",
      },
      {
        label: "Urgent Daily Campaign",
        title: "Urgent Daily Campaign",
        icon: LuConstruction,
        href: "/admin/wip/urgent-daily-campaign",
      },
      {
        label: "Axis Data count",
        title: "Axis Data count",
        icon: LuConstruction,
        href: "/admin/wip/axis-data-count",
      },

      // {
      //     label: "Settlement Letter Analysis",
      //     title: "Settlement Letter Analysis",
      //     icon: LuConstruction,
      //     href: "/admin/wip/settlement-letter-analysis",
      // },
    ],
  },
  {
    label: "Hide Modules",
    items: [
      {
        label: "Daily Report",
        title: "Daily Settlement Report",
        icon: PiClipboardText,
        href: "/admin/settlements/daily-report",
      },
      {
        label: "IVR Restart Summary",
        title: "IVR Restart Summary",
        icon: PiMicrophone,
        href: "/admin/campaigns/ivr-restart-summary",
      },
      {
        label: "Digital Report",
        title: "Digital Report",
        icon: HiOutlineNewspaper,
        href: "/admin/campaigns/digital-report",
      },
      {
        label: "Leads",
        title: "Leads",
        icon: PiChartBarHorizontalLight,
        href: "/admin/collection-perfomance/leads",
      },
    ],
  },
];


export const DailyOpsSidebar = [
  {
    label: "Daily Ops",
    items: [
      {
        label: "Call Quality",
        title: "Call Quality",
        icon: HiOutlinePhone,
        href: "/admin/daily-ops/call-quality",
      }
    ],
  },

 ]
export const Chatpanelsidebr = [
  {
    label: "Chat Panel",
    items: [
      {
        label: "Chat Panel",
        title: "Chat Panel",
        icon: MdOutlineSupportAgent,
        href: "/admin/chat-panel/chat",
      },
      {
        label: "Alt Chat Panel",
        title: "Alt Chat Panel",
        icon: MdOutlineSupportAgent,
        href: "/admin/chat-panel/alt-chat",
      },
    ],
  },

 ]
const allItems = sidebarData.map((item) => item.items).flat();

export const getPageTitle = (location) => {
  const page = allItems.find((item) => item.href === location);
  return page?.title ?? "Admin Panel";
};
